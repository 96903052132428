<template>
  <div class="footer-menu">
    <div class="footer-menu__column">
      <a class="menu-link" href="">Whitepaper</a>
      <a class="menu-link" href="">Docs</a>
      <a class="menu-link" href="">About</a>
    </div>
    <div class="footer-menu__column">
      <a class="menu-link" href="">Careers</a>
      <a class="menu-link" href="">Support</a>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.footer-menu {
  display: flex;
  gap: 40px;

  &__column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
</style>
